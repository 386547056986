<template>
<v-col cols="12" sm="11" md="10" lg="7" class="mx-auto px-0 mt-5">

  <v-tabs color="secondary" centered class="mb-5 mx-auto" style="max-width:500px;border-radius:4px;">
    <v-tab @click="setWalletType('main')" style="letter-spacing:0px; text-transform:none!important;">
      <b>Compte courrant</b>
    </v-tab>
    <v-tab @click="setWalletType('deposit')" style="letter-spacing:0px; text-transform:none!important;">
      <b>Compte de dépot</b>
    </v-tab>
  </v-tabs>

  <WalletView v-if="wallet!=null" :wallet="wallet"/>
</v-col>
</template>

<script>

import WalletView from '@/components/wallet/WalletView.vue'

export default {
  name: 'auth',
  components: { WalletView },
  data: () => ({
    wallet: null,
    walletType: 'main'
  }),

  async mounted(){
    this.refreshData()
  },

  methods: {
    setWalletType(walletType){
      this.walletType = walletType
      this.refreshData()
    },
    refreshData(){
      if(this.walletType == 'main')
        this.wallet = this.$store.state.auth.user.walletMain
      else
        this.wallet = this.$store.state.auth.user.walletsDeposit[0]
    }
  },
  
  computed: {
    
  },
  watch: {
     
      '$store.state.auth.user': { immediate: true, 
          async handler (){  //oldVal, val) {
            //console.log("wallet changed !", this.wallet.uid)

            this.refreshData()
          }
      },
  }, 
}
</script>
